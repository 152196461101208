import { Search, Tabs, Tab, Tag, Overlay, List, Icon, Cell, NoticeBar, Image, Grid, GridItem, Swipe, SwipeItem } from 'vant'
import Tabbar from '../common/Tabbar.vue'
import Login from '../../module/intercept/Login.vue'
import Share from '../../module/common/Share.vue'
import Loading from '../../module/common/Loading.vue'
import LogVisit from '../../module/common/LogVisit.vue'
import Call from '../../module/common/Call.vue'
import Clue from '../../module/common/Clue.vue'
import '../../../assets/icon/font-icon.js'
export default {
    components: {
        Tabbar: Tabbar,
        Login: Login,
        Share: Share,
        Loading: Loading,
        LogVisit: LogVisit,
        Call: Call,
        Clue: Clue,
        [Search.name]: Search,
        [Tabs.name]: Tabs,
        [Tab.name]: Tab,
        [Tag.name]: Tag,
        [Overlay.name]: Overlay,
        [List.name]: List,
        [Cell.name]: Cell,
        [Icon.name]: Icon,
        [NoticeBar.name]: NoticeBar,
        [Image.name]: Image,
        [Grid.name]: Grid,
        [GridItem.name]: GridItem,
        [Swipe.name]: Swipe,
        [SwipeItem.name]: SwipeItem
    },
    data () {
        return {
            platform: '',
            sharer: '',
            page: { current: 0, size: 10, last: 0 },
            condition: { roName: '', roInstIndustry: '', roInstNature: '', roInstType: '', roInstScale: '', roType: '' },
            operator: { name: '', address: '', linkPerson: '', linkPhone: '', linkWechat: '', type: '' },
            loadingShow: false,
            wechatShow: false,
            guideShow: true,
            finished: false,
            classifyList: [],
            code: '',
            name: '',
            taskActive: 'all',
            taskList: [],
            rcmdTasks: [],
            person: '',
            loginState: 'N',
            studentState: 'N',
            contentPage: { current: 0, size: 5, last: 0 },
            contentList: [],
            informPage: { current: 0, size: 5, last: 0 },
            informList: []
        }
    },
    mounted () {
        document.title = '工作招聘'
        this.$refs.login.validate()
        this.createVistClue()
    },
    methods: {
        init () {
            this.platform = window.sessionStorage.getItem(this.SESSION_PLATFORM)
            this.$refs.logVisit.createLog()
            var token = window.sessionStorage.getItem(this.SESSION_TOKEN)
            if (token !== undefined && token !== '' && token !== null) {
                var guide = window.sessionStorage.getItem('guide_show')
                if (guide !== 'N') {
                    // this.guideShow = true
                    // window.sessionStorage.setItem('guide_show', 'N')
                }
                this.retrieveClassifys()
                this.retrieveTaskHotList()
                this.retrieveContentList()
                this.retrieveInformList()
                this.initShare()
            }
        },
        async retrieveClassifys () {
            var pd = { upperCode: '0' }
            const { data: res } = await this.$http.post(this.BMS_URL + '/recruit/classify/retrieveClassifyList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.classifyList = res.data
            }
        },
        initShare () {
            this.$refs.share.default('main')
        },
        async retrieveContentList () {
            this.loadingShow = true
            this.contentPage.current = this.contentPage.current + 1
            var pd = {
                module: 'APP_INDEX',
                type: 'HOT',
                current: this.contentPage.current,
                size: this.contentPage.size
            }
            var { data: res } = await this.$http.post(this.BMS_URL + '/content/index/retrieveDisplayIndexList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.contentList = res.data
                this.contentPage.last = res.page.last
            }
            this.loadingShow = false
        },
        contentDetail (type, code) {
            if (type === 'ATC') {
                var key = window.const.global.CONTENT_ARTICLE_INDEX_BACK
                window.sessionStorage.setItem(key, window.location.href)
                this.$router.push({ path: '/mde/content/article', query: { indexCode: code } })
            }
        },
        async retrieveInformList () {
            this.loadingShow = true
            this.informPage.current = this.informPage.current + 1
            var pd = {
                module: 'APP_INDEX',
                type: 'HOT',
                current: this.informPage.current,
                size: this.informPage.size
            }
            var { data: res } = await this.$http.post(this.BMS_URL + '/inform/taskAPC/retrieveDisplayTaskList', this.$qs.stringify(pd))
            if (res.status === '200') {
                this.informList = res.data
                this.informPage.last = res.page.last
                console.log(res.data)
            }
            this.loadingShow = false
        },
        informDetail (code) {
            var key = window.const.global.INFORM_DETAIL_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/inform/detail', query: { code: code } })
        },
        async retrieveTaskHotList () {
            if (!this.finished) {
                this.loadingShow = true
                this.page.current = this.page.current + 1
                var operator = window.sessionStorage.getItem(this.SESSION_OPERATOR)
                var pd = { operatorCode: operator, current: this.page.current, size: this.page.size }
                var { data: res } = await this.$http.post(this.BMS_URL + '/recruit/taskAPC/retrieveTaskList', this.$qs.stringify(pd))
                if (res.status === '200') {
                    this.taskList = this.taskList.concat(res.data)
                    this.page.last = res.page.last
                    if (res.data.length <= 0) {
                        this.finished = true
                    }
                }
                this.loadingShow = false
            }
        },
        allTask () {
            this.$router.push({ path: '/mde/recruit/search' })
        },
        classifySearch (code) {
            this.$router.push({ path: '/mde/recruit/search', query: { classifyCode: code } })
        },
        setting () {
            this.$router.push({ path: '/mde/person/resumeEdit', query: { inviterCode: this.sharer } })
        },
        consult (code) {
            var key = window.const.global.RECRUIT_CONSULT_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/recruit/consult', query: { taskCode: code, module: 'USR' } })
        },
        bind () {
            this.$router.push({ path: '/mde/bind' })
        },
        content () {
            var key = window.const.global.CONTENT_HOME_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/content/home' })
        },
        search () {
            this.$router.push({ path: '/mde/recruit/search' })
        },
        inform () {
            var key = window.const.global.INFORM_HOME_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/inform/home' })
        },
        recruit () {
            var key = window.const.global.RECRUIT_HOME_INDEX_BACK
            window.sessionStorage.setItem(key, window.location.href)
            this.$router.push({ path: '/mde/recruit/home' })
        },
        helper () {
            this.guideShow = false
            this.$router.push({ path: '/work/helper/home' })
        },
        createVistClue () {
            var param = { url: window.location.href }
            var content = '工作推荐首页访问'
            this.$refs.clue.createClue('RECRUIT_VIST_HOME', '0', JSON.stringify(param), content)
        },
        back () {
            this.$router.push({ path: '/integrate/home' })
        }
    }
}
