<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="点击搜索证书"
            @click="search"
            readonly
            ref="keyword"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item @click="helper()">
        <img
          class="img"
          :src="require('../../../assets/images/recruit/banner01.jpg')"
        />
      </van-swipe-item>
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../assets/images/recruit/banner.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid>
        <van-grid-item text="攻略指南" key="1" @click="content()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-news"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="公招发布" key="2" @click="inform()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-govment"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="补岗招聘" key="3" @click="recruit()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-recruit"></use>
            </svg>
          </template>
        </van-grid-item>
        <van-grid-item text="智能小秘书" key="1" @click="helper()">
          <template #icon>
            <svg class="icon" aria-hidden="true">
              <use xlink:href="#icon-robot"></use>
            </svg>
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- <div class="content">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 攻略指南</van-col
        >
        <van-col span="12" class="btn color-m"
          ><div @click="content">更多 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <div class="list">
        <div
          class="item text-short"
          v-for="content in contentList"
          :key="content.code"
          @click="contentDetail(content.type, content.code)"
        >
          · {{ content.title }}
        </div>
      </div>
    </div> -->
    <div class="inform">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 公招发布</van-col
        >
        <van-col span="12" class="btn color-m"
          ><div @click="inform()">更多 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <div class="list">
        <div
          class="item text-short"
          v-for="inform in informList"
          :key="inform.code"
          @click="informDetail(inform.code)"
        >
          · {{ inform.name }}
        </div>
      </div>
    </div>
    <div class="job">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 热门职位</van-col
        >
        <van-col span="12" class="btn color-m"
          ><div @click="allTask()">职位筛选 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        class="task-list"
        :immediate-check="false"
        @load="retrieveTaskHotList"
      >
        <template #default>
          <van-row
            class="task bdc-b1s-gray"
            v-for="task in taskList"
            :key="task.code"
            @click="consult(task.code)"
          >
            <van-col span="24" class="content">
              <van-row>
                <van-col span="24" class="name text-short">{{
                  task.name
                }}</van-col>
              </van-row>
              <van-row>
                <van-col span="17" class="label">
                  <span
                    v-for="item in task.label"
                    :key="item.name"
                    class="item bgc-gray"
                    >{{ item.name }}</span
                  >
                </van-col>
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'DAY'"
                  >{{ task.salaryMin.toFixed(1) }} -
                  {{ task.salaryMax.toFixed(1) }}元/天</van-col
                >
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'MOT'"
                  >{{ (task.salaryMin / 1000).toFixed(1) }} -
                  {{ (task.salaryMax / 1000).toFixed(1) }}K/月</van-col
                >
                <van-col
                  span="7"
                  class="salary fc-cyan"
                  v-if="task.salaryType === 'YER'"
                  >{{ (task.salaryMin / 10000).toFixed(1) }} -
                  {{ (task.salaryMax / 10000).toFixed(1) }}万/年</van-col
                >
              </van-row>
              <van-row>
                <van-col span="17" class="time"
                  >日期：{{ task.timeStart }}到{{ task.timeEnd }}</van-col
                >
                <van-col span="7" class="amount"
                  >人数:{{ task.amount }}人</van-col
                >
              </van-row>
            </van-col>
          </van-row>
          <van-row v-if="taskList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <van-overlay :show="guideShow">
      <div class="wrapper" @click="guideShow = false">
        <div class="guide">
          <van-image
            class="img"
            :src="require('../../../assets/images/helper.jpg')"
          ></van-image>
          <van-button
            plain
            :color="COLOR_M"
            size="mini"
            class="btn"
            icon="info-o"
            @click="helper"
            >立即体验</van-button
          >
        </div>
      </div>
    </van-overlay>
    <Clue ref="clue" />
    <Call ref="call" win-show="false" entry-show="true" />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="RCT" module="RCT_INDEX" />
    <Tabbar active="home" />
    <!-- <Tabbar mode="enroll" active="home" /> -->
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
